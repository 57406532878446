<template>
  <div ref="content" class="content">
    <h3 class="text-center invoice-title">Invoice</h3>
    <div class="lg:flex gap-3 mb-4 content">
      <div class="lg:w-6/12">
        <strong>Ldi load id:1</strong>
        <strong>Invoice#:1</strong>
      </div>
      <div class="lg:w-6/12 text-right">Date: 12/23/2021</div>
    </div>
    <div class="mb-4 content">
      <p>Demo Demolition</p>
      <p>California</p>
      <p>313-932-9399</p>
    </div>
    <div class="lg:flex gap-3 mb-4 content cm">
      <div class="lg:w-6/12">
        <strong>Customer</strong>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
      </div>
      <div class="lg:w-3/12"></div>
      <div class="lg:w-3/12">
        <strong>Payable to</strong>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
        <p>Logistics Dynamics Inc.</p>
      </div>
    </div>
    <table class="table">
      <tbody>
        <tr>
          <td>Description</td>
          <td style="width: 150px">Amount</td>
        </tr>
        <tr>
          <td>Baltmore Co.</td>
          <td>$500</td>
        </tr>
        <tr>
          <td>Lumper</td>
          <td>$256</td>
        </tr>
        <tr>
          <td>Total</td>
          <td>$756</td>
        </tr>
      </tbody>
    </table>
  </div>
  <button class="btn" @click="downloadpdf">pdf</button>
</template>

<script>
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export default {
  methods: {
    downloadpdf() {
      let pdfName = "test";
      const html = this.$refs.content.innerHTML;
      window.html2canvas = html2canvas;

      var doc = new jsPDF("p", "pt", "a4");

      doc.html(html, {
        callback: function (doc) {
          var out = doc.output();
          var url = btoa(out);
          alert(url);

          doc.save(pdfName + ".pdf");
        },
        width: 500,
        windowWidth: 500,
        x: 50,
        y: 20,
      });
    },
  },
};
</script>

<style scoped>
.invoice-title {
  font-size: 24px;
  color: #181818;
  text-transform: uppercase;
  margin-bottom: 20px;
}
table {
  width: 100%;
}
table,
td,
th {
  font-size: 11px;
  border: 0.5px solid #6f6f6f;
  border-collapse: collapse;
}
td {
  padding: 6px;
}
.content {
  font-size: 11px;
}
.cm {
  margin-bottom: 20px;
}
</style>
